import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./_spinner.scss";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Progress() {
  return (
    <div className="circlar_progress_container">
      <Spin indicator={antIcon} />
    </div>
  );
}
